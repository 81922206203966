import { IProductPriceViewModel } from './Product';

export interface IProductCarouselGtmProduct {
	blainNumber: string;
	price: IProductPriceViewModel;
	vendorBrand: string;
	productName: string;
	belowMap: boolean;
	priceMode: string;
}
export interface IProductCarouselProduct extends IProductCarouselGtmProduct {
	imageUrl: string;
	name: string;
	avgRating: number;
	numReviews: number;
	productUrl: string;
	isOnSale: boolean;
	imageUrlWebp: string;
	flagText: string;
	priceString: string;
	wasPriceString: string;
	modelName: string;
	isGroupedProduct: boolean;
	isRestrictedProduct: boolean;
	isNotBuyableOnline: boolean;
}

export interface ILazyCarousel {
	carouselUrl: string;
}

export type ProductCarousel = IProductCarousel | IHawkProductCarousel;
export function isHawkCarousel(carousel: ProductCarousel): carousel is IHawkProductCarousel {
	return 'widgetType' in carousel;
}

export interface IProductCarousel {
	name: string;
	title: string;
	seeAllUrl: string;
	products: IProductCarouselProduct[];
	carouselType: CarouselType;
	lazyLoad: boolean;
}

export interface IHawkProductCarousel extends IProductCarousel {
	widgetGuid: string;
	requestId: string;
	widgetType: HawkRecommendationsWidgetType;
}

export enum CarouselType {
	ALSO_VIEWED = 1,
	VENDOR_BRAND,
	CROSS_SELL,
	TRENDING,
	PERSONALIZED,
	RECENTLY_VIEWED,
	GIFT_IDEAS,
	NEW_PRODUCTS,
	TOP_BY_VENDOR,
	TOP_BY_CATEGORY,
	SALE_COLLECTION,
	TOP_BY_SCORE,
	DRIVE_THRU_ON_SALE,
	DRIVE_THRU_POPULAR,
	DRIVE_THRU_BUY_AGAIN,
	DRIVE_THRU_SEASONAL,
	DRIVE_THRU_WEATHER,
	DRIVE_THRU_SEASONAL_WEATHER,
	ALSO_PURCHASED,
	STORE_EVENTS,
	SIMILAR_PRODUCTS,
	HAWK_RECOMMENDATIONS,
	DYNAMIC,
	MODELED,
	CROSS_SELL_HYBRID,
	HAWK_LANDING_PAGE,
	BUY_IT_AGAIN,
}

export enum HawkRecommendationsWidgetType {
	CART_ALSO_ADDED = 1,
	CART_ALSO_BOUGHT,
	CART_MAY_ALSO_LIKE,
	CART_SIMILAR_ITEMS,
	PDP_ALSO_VIEWED,
	PDP_BEST_SELLERS,
	PDP_BOUGHT_TOGETHER,
	PDP_MAY_ALSO_LIKE,
	PDP_PLA_MORE_LIKE_THIS,
	PDP_VIEWED_THAN_BOUGHT,
	HOME_BEST_SELLERS,
	HOME_TOP_PRODUCTS_BY_CATEGORY,
	HOME_TRENDING,
	HOME_MAY_ALSO_LIKE,
	PDP_TOP_PRODUCTS_BY_BRAND,
	THANK_YOU_PAGE,
	PDP_VIEWED_THEN_BOUGHT_WITH_FALLBACK,
	PDP_COMPARE_SIMILAR
}

export interface ICarouselViewData {
	type: number;
	carouselName: string,
	url: string;
	position?: number;
}

export interface ICarouselViewGtmData {
	itemListID: string;
	itemListName: string;
	carouselProducts: IProductCarouselGtmProduct[];
}

export interface IHawkRecommendationsCarouselData {
	hawkWidgetType: HawkRecommendationsWidgetType;
	hawkRequestId: string;
}

export type ICarouselClickData = Partial<IHawkRecommendationsCarouselData> & {
	currentPrice: number;
	blainNumber: string;
	carousel: ICarouselViewData;
	itemIndex: number;
}

export interface ICarouselClickGtmData {
	itemListID: string;
	itemListName: string;
	currentPrice: number;
	blainNumber: string;
	vendorBrand: string;
	productName: string;
	belowMap: boolean,
	priceMode: string,
	index: number;
}

export interface IImageCarouselOptions {
	speed: number;
	autoplay: boolean;
	autoplayDelay: number;
	draggable: boolean;
}